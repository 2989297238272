import React, { useState } from "react";
import PreTranslation from "components/PreTranslation/PreTranslation";
import SEO from "components/seo";
import ToolFaq from "components/QualityTools/ToolFaq";
import { PreTranslationToolFaqData } from "components/FAQ/config";
import Layout from "components/layout";
import ResultPage from "components/PostTranslation/Result";

const Result = () => {
  return (
    <Layout>
      <SEO
        title="Post-Translation Toolkit"
        description="The Tomedes Post-Translation Toolkit is designed to enhance translated content by evaluating quality, generating bilingual glossaries, and ensuring consistency of terminology."
        slug="/tools/pre-translation"
      />
      <ResultPage />
    </Layout>
  );
};

export default Result;

import React, { useState, useEffect, useRef } from "react";
import { Languages } from "components/QualityTools/LanguagesData";
import { parseResult } from "utils/toolsresult";
import DownloadIcon from "assets/downloadtool.jpg";
import CopyIcon from "assets/copytools.jpg";
import { Tooltip } from "react-tooltip";
import InfoIcon from "assets/info.svg";
const GlossaryGenerator = ({ analysisResult }) => {
  const [divHeight, setDivHeight] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showOutput, setShowOutput] = useState(false);
  const [transtalteFromInput, setTranslateFromInput] = useState("");
  const [transtalteToInput, setTranslateToInput] = useState("");
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const refDiv = useRef(null);
  const outputRef = useRef(null);
  const [wordCountInput, setWordCountInput] = useState(0);
  const [wordCountOutput, setWordCountOutput] = useState(0);
  const [copySuccess, setCopySuccess] = useState("Copy");
  const [isLgScreen, setIsLgScreen] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsLgScreen(window.innerWidth > 1024);
      const handleResize = () => {
        setIsLgScreen(window.innerWidth > 1024);
      };

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  const findLanguageNameByCode = (code) => {
    const language = Languages.find((lang) => lang.code === code);
    return language ? language.name : null;
  };

  const sourceLanguageName = findLanguageNameByCode(
    analysisResult?.meta?.source_language_code
  );
  const targetLanguageName = findLanguageNameByCode(
    analysisResult?.meta?.target_language_code
  );

  const resultValue = parseResult(analysisResult?.result);
  const glossary = resultValue?.glossary;

  useEffect(() => {
    if (refDiv?.current) {
      setDivHeight(refDiv?.current?.base?.offsetHeight);
    }
  }, [refDiv, analysisResult]);

  const copyText = () => {
    if (outputRef.current) {
      const textToCopy = outputRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      setCopySuccess("Copied");
      setTimeout(() => {
        setCopySuccess("Copy");
      }, 1500);
    }
  };
  const downloadCSV = () => {
    if (!resultValue || !Array.isArray(resultValue.glossary)) return;

    const csvRows = [];
    csvRows.push([sourceLanguageName, targetLanguageName]);

    resultValue.glossary.forEach((item) => {
      csvRows.push([item.source_term, item.translated_term]);
    });

    const csvString = csvRows.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "glossary.csv";
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <div className="bg-white rounded-lg p-4">
      <div className=" w-full px-3 rounded-lg mb-4 flex items-center justify-between gap-5">
        <p className="text-base font-opensans text-center font-semibold flex gap-2 items-center">
          <span>Bilingual Glossary</span>
          <img src={InfoIcon} alt="info" />
        </p>
        <div className="flex gap-4 items-center justify-between  ">
          <button
            data-tooltip-id="copy-tooltip"
            data-tooltip-content={copySuccess}
            onClick={copyText}
            className=""
          >
            <img src={CopyIcon} alt="/copyicons" />
          </button>
          <button
            data-tooltip-id="download-tooltip"
            data-tooltip-content="Download"
            onClick={downloadCSV}
            className=""
          >
            <img src={DownloadIcon} alt="/downloadicon" />
          </button>
        </div>
        <Tooltip
          id="copy-tooltip"
          place="top"
          multiline={true}
          className="z-50"
        />
        <Tooltip
          id="download-tooltip"
          place="top"
          multiline={true}
          className="z-50"
        />
      </div>
      <ul
        style={{ maxHeight: isLgScreen ? `${divHeight - 22}px` : "" }}
        className=" w-full text-sm text-left rtl:text-right relative overflow-y-auto  shadow-md sm:rounded-lg"
        ref={outputRef}
      >
        <li className="grid grid-cols-2">
          <p className="px-6 py-3 bg-[#BDD3FF] border-none outline-none text-[#575757] font-opensans text-center font-normal">
            {sourceLanguageName}
          </p>
          <p className="px-6 py-3 bg-[#E0EBFF] border-none outline-none text-[#575757] font-opensans text-center font-normal">
            {targetLanguageName}
          </p>
        </li>

        <div className="mt-2 grid gap-2">
          {glossary &&
            glossary?.map((item, index) => (
              <li className="flex" key={index}>
                <p className="p-3 flex-1 text-start border-none outline-none font-bold text-[#202020] text-base font-opensans bg-[#E0EBFF] whitespace-wrap break-words">
                  {item.source_term}
                </p>
                <p className="p-3 bg-[#F0F5FF] flex-1 border-none outline-none text-start text-[#202020] font-opensans text-base font-normal whitespace-wrap break-words">
                  {item.translated_term}
                </p>
              </li>
            ))}
        </div>
      </ul>
    </div>
  );
};

export default GlossaryGenerator;
